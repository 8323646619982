<template>
  <div>

    <draggable tag="div" v-bind="dragOptions" :list="template.confirm_item" @start="drag = true" @end="drag = false">
      <b-row v-for="(item, item_index) in template.confirm_item" :key="item_index">
        <b-card align="left" class="w-100 my-2 mx-sm-3" body-class="px-1 p-md-3" 
            header-tag="header" footer-tag="footer" footer-class="p-2" :style="{cursor: drag ? 'grabbing' : 'default'}">
          <template v-slot:header>
            <b-form-group label-cols="2" label-cols-md="1" class="my-0" :label="'質問'+(item_index+1)">
              <b-input-group>
                <b-input class="question" v-model="item.question"/>
                <b-dropdown class="questionType" toggle-class="form-control" variant="outline-secondary" right>
                  <template #button-content>
                    <b-icon-record-circle v-if="qHandler.isTypeRadio(item)" v-b-popover.hover.top="'単一選択'"/>
                    <b-icon-check-square v-if="qHandler.isTypeCheck(item)" v-b-popover.hover.top="'複数選択'"/>
                    <b-icon-text-left v-if="qHandler.isTypeText(item)" v-b-popover.hover.top="'記述式'"/>
                  </template>
                  <b-dropdown-header>質問形式</b-dropdown-header>                
                  <b-dropdown-item @click="qHandler.setTypeRadio(item)"><b-icon-record-circle class="mr-2"/>単一選択</b-dropdown-item>
                  <b-dropdown-item @click="qHandler.setTypeCheck(item)"><b-icon-check-square class="mr-2"/>複数選択</b-dropdown-item>
                  <b-dropdown-item @click="qHandler.setTypeText(item)"><b-icon-text-left class="mr-2"/>記述式</b-dropdown-item>
                </b-dropdown>
              </b-input-group>
            </b-form-group>
            <b-row v-if="validator.confirm_item.$each[item_index].$error">
              <b-col>
                <div class="error" v-if="!validator.confirm_item.$each[item_index].question.required">質問を入力してください</div>
              </b-col>
            </b-row>
          </template>

          <template v-if="qHandler.isTypeRadio(item) || qHandler.isTypeCheck(item)">
            <div class="choiceGroup" v-for="(choice, index) in item.choice" :key="index">
              <b-input-group>
                <b-input-group-prepend>
                  <label class="mx-3 mt-1">{{ index + 1 }}</label>
                </b-input-group-prepend>
                <input class="form-control choice" :value="choice" @change.self="item.choice.splice(index, 1, $event.target.value)"/><!-- not bind by b-input -->
                <b-input-group-append>
                  <b-button class="delChoice" variant="outline-secondary" @click="item.choice.splice(index, 1)" tabindex="-1">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="choiceGroup" v-if="!!item.choiceOther">
              <b-input-group>
                <b-input-group-prepend>
                  <label class="mx-3 mt-1">{{ item.choice.length + 1 }}</label>
                </b-input-group-prepend>
                <label class="form-control choice text-secondary border-0">
                  その他<span class="smaller">（回答時にテキスト入力）</span>
                </label>
                <b-input-group-append>
                  <b-button class="delChoice" variant="outline-secondary" @click="item.choiceOther=false,item.choice.splice()" tabindex="-1">
                    <b-icon icon="x" aria-hidden="true"></b-icon>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </div>

            <b-row class="mt-2" v-if="validator.confirm_item.$each[item_index].$error">
              <b-col>
                <div class="error" v-if="!validator.confirm_item.$each[item_index].choiceMinLength">選択肢は２つ以上追加してください</div>
                <div class="error" v-if="validator.confirm_item.$each[item_index].choice.$each.$anyError">選択肢を入力してください</div>
              </b-col>
            </b-row>

            <div class="mt-3 pl-2">
              <b-button class="addChoice" variant="outline-info" @click="item.choice.push('')">選択肢追加</b-button>
              <span class="mx-2"/>
              <b-button class="addOther" variant="outline-info" v-if="!item.choiceOther" @click="item.choiceOther=true,item.choice.splice()">
                その他を追加
              </b-button>
            </div>
          </template>
          
          <template v-if="qHandler.isTypeText(item)">
            <div class="choiceGroup">
              <b-input-group>
                <b-input-group-prepend>
                  <label class="mx-3 mt-1">-</label>
                </b-input-group-prepend>
                <label class="form-control choice text-secondary border-0">
                  自由入力<span class="smaller">（回答時にテキスト入力）</span>
                </label>
              </b-input-group>
            </div>
          </template>

          <template v-slot:footer>
            <b-container>
              <b-row>
                <b-col cols="4" sm="3" class="mt-1">
                  <b-checkbox class="choiceRequired" v-model="item.required" switch>必須</b-checkbox>
                </b-col>
                <b-col class="text-right">
                  <b-button class="delItem" size="sm" variant="outline-danger" v-b-popover.hover.top="'質問削除'" @click="delItem(item_index)">
                    <b-icon icon="trash" aria-hidden="true"></b-icon>
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </b-card>
      </b-row>
    </draggable>

    <b-row>
      <b-col class="text-left">
        <b-button class="addItem" @click="addItem">質問追加</b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import draggable from 'vuedraggable'

import { default as qHandler } from '@/const/questionnaireHandler'

export default {
  name: 'QuestionnaireItems',
  props: ['template', 'validator'],
  components: {
    'draggable': draggable,
  },
  data () {
    return {
      drag: false,
      qHandler
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'qitem',
        disabled: window.innerWidth <= 800,
        ghostClass: 'ghost'
      }
    }
  },
  methods: {
    addItem () {
      this.template.confirm_item.push(this.emptyDataItem())
    },
    delItem (item_index) {
      this.template.confirm_item.splice(item_index, 1)
      this.$tours['helpTour'].stop()
    },
    emptyDataItem () {
      return {
        type: 'radio',
        choice: ['', ''],
        required: true
      }
    }
  }

}
</script>

<style scoped>
.choice { border-radius: 0.1rem !important; }
.delChoice { border: 0 !important; }
</style>