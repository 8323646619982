<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <b-alert :show="!!bounceWarning" variant="warning" class="mb-4" dismissible>
      <b-icon-exclamation-triangle class="lead mr-2"/>{{ bounceWarning }}
    </b-alert>
    
    <b-form v-if="!loading" class="inputForm" @submit.stop.prevent>
      <b-form-group class="my-2" label-cols-sm="3" label="メンバーID" label-for="userId"
          :description="isNew ? 'メンバーを特定するための値で、一覧ではID順に表示されます' : ''">
        <b-input id="userId" type="text" placeholder="英数字4文字以上" v-model.trim="user.user_id" :disabled="!!p_user_id" :autofocus="!p_user_id"
          @blur="$v.user.user_id.$touch()"/>
      </b-form-group>
      <b-row v-if="$v.user.user_id.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.user_id.required">メンバーIDを入力してください</div>
          <div class="error" v-if="!$v.user.user_id.minLength">メンバーIDは{{$v.user.user_id.$params.minLength.min}}文字以上としてください</div>
          <div class="error" v-if="!$v.user.user_id.alphaNum">メンバーIDは英数字のみとしてください</div>
          <div class="error" v-if="!$v.user.user_id.isUnique">同じメンバーIDが既に使用されています</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="氏名（漢字）" label-for="userName">
        <b-input id="userName" type="text" v-model.trim="user.user_name" :autofocus="!!p_user_id"/>
      </b-form-group>
      <b-row v-if="$v.user.user_name.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.user_name.required">氏名（漢字）を入力してください</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="所属／分類" label-for="userTags"
          description="メール送信時にタグによる対象者選択が行えます">
        <b-tags id="userTags" v-model="user.tags" size="lg" class="mb-2" add-on-change no-outer-focus>
          <template v-slot="{ tags, inputAttrs, addTag, removeTag }">

            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
              <li v-for="tag in sortedUserTagIds(tags)" :key="tag" class="list-inline-item">
                <b-tag @remove="removeTag(tag)" class="userTag">{{ userTagMap[tag] }}</b-tag>
              </li>
            </ul>

            <b-input-group>
              <b-select v-bind="inputAttrs" v-model="tagSelected" @input="addTag(tagSelected)" @change="tagSelected=''"
                :disabled="availableOptions.length === 0" :options="availableOptions" value-field="id" text-field="name">
                <template #first>
                  <option disabled value="">タグを選択してください{{tags.length ? '（複数選択可）' : '（省略可）'}}</option>
                </template>
              </b-select>
              <b-input-group-append>
                <b-button v-b-modal.modal-editTag>編集</b-button>
              </b-input-group-append>
            </b-input-group>

          </template>
        </b-tags>

        <b-modal id="modal-editTag" title="タグ編集" :hide-header="false" :hide-footer="true" headerClass="modalHeader c-theme" 
            no-close-on-backdrop centered>
          <template #default="{ close }">
            <UserTags :close="close"/>
          </template>
        </b-modal>

      </b-form-group>

      <b-form-group v-if="!isNew && useMobile" class="my-2" label-cols-sm="3" label="アプリ連携">
        <label v-if="user.device_token" class="col-form-label">
          <b-icon icon="check" class="mr-1" variant="success"></b-icon>登録あり
          <span v-if="user.device_token.length > 1">（{{user.device_token.length}}台）</span>
        </label>
        <label v-else class="col-form-label">登録なし</label>
      </b-form-group>

      <b-row class="mt-3 mb-3">
        <b-col offset-sm="3" class="text-center">
          <span>＜メール送信先＞</span>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="メールアドレス" label-for="mailAddr">
        <b-input id="mailAddr" type="text" v-model.trim="user.mail_address" :placeholder="(useMobile ? '省略可' : '')"/>
      </b-form-group>
      <b-row v-if="$v.user.mail_address.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.mail_address.required">メールアドレスまたはSMSを入力してください</div>
          <div class="error" v-if="!$v.user.mail_address.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="サブメールアドレス１" label-for="subMailAddr1">
        <b-input id="subMailAddr1" type="text" v-model.trim="user.sub_mail_address1" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.sub_mail_address1.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.sub_mail_address1.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="サブメールアドレス２" label-for="subMailAddr2">
        <b-input id="subMailAddr2" type="text" v-model.trim="user.sub_mail_address2" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.sub_mail_address2.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.sub_mail_address2.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="SMS（電話番号）" label-for="phoneNo">
        <b-input id="phoneNo" type="text" v-model.trim="user.phone_no" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.phone_no.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="user.phone_no && $v.user.phone_no.$error">11桁の数値を入力してください</div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col offset-sm="3">
          <b-checkbox id="notify" v-model="user.notify" switch>登録した送信先に設定完了メールを配信する</b-checkbox>
        </b-col>
      </b-row>

      <b-row class="buttonGroup text-center" :show="loading && !error">
        <b-col>
          <div v-if="isNew">
            <b-button id="insertButton" variant="primary" @click="createData">
              <b-spinner small v-if="sending.create"></b-spinner>登録する
            </b-button>
          </div>
          <div v-if="!isNew">
            <b-button id="updateButton" variant="primary" @click="updateData">
              <b-spinner small v-if="sending.update"></b-spinner>更新する
            </b-button>
            <span class="mx-3"/>
            <b-button id="deleteButton" variant="outline-danger" @click="deleteDataWithConfirm">
              <b-spinner small v-if="sending.delete"></b-spinner>削除する
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
    <b-button variant="warning" class="help"  @click="$tours['helpTour'].start()">
      <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
    </b-button>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { required, requiredIf, minLength, maxLength, alphaNum, numeric, email } from 'vuelidate/lib/validators'
import UserTags from '@/components/UserTags.vue'

export default {
  name: 'UserDetail',
  components: {
    UserTags
  },
  props: ['isNew', 'p_user_id', 'p_temp_user_id'],
  data () {
    return {
      loading: false,
      sending: {},
      self: false,
      user: {},
      tagSelected: '',
      bounceWarning: '',
      error: null,
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      }
    }
  },
  computed: {
    ...mapGetters({
      useMobile: 'auth/useMobile',
      userTags: 'auth/userTags',
      userTagMap: 'auth/userTagMap',
      sortedUserTagIds: 'auth/sortedUserTagIds'
    }),
    availableOptions() {
      return this.userTags.filter(opt => !this.user.tags || this.user.tags.indexOf(opt.id) === -1)
    }
  },
  validations: {
    user: {
      user_id: {
        required,
        minLength: minLength(4),
        alphaNum,
        async isUnique(value) {
          if (!value || !this.isNew) return true
          const params = {
            'corporation_id': this.memberCorporationId, 
            'user_id': value
          }
          return await this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
            .then(res => res.data.length == 0)
        }
      },
      user_name: {
        required
      },
      mail_address: {
        required: requiredIf(function(user) {
          return !this.useMobile && !user.phone_no
        }),
        email
      },
      sub_mail_address1: {
        email
      },
      sub_mail_address2: {
        email
      },
      phone_no: {
        required: requiredIf(function(user) {
          return !this.useMobile && !user.mail_address
        }),
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  watch: {
    '$route': 'init'
  },
  methods: {
    init () {
      if (this.isNew) {
        this.self = !!this.p_temp_user_id
        if (this.self) {
          this.fetchTempData()
        } else {
          this.user = {
            user_id: this.p_user_id
          }
          this.user.notify = true
        }
      } else {
        this.fetchData()
      }
      this.user.tags |= [] 
    },

    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps = []
      if (this.isNew) steps.push({
        target: '#userId',
        content: 'メンバーのIDを<b>英数字4桁以上</b>で設定してください。<br>一覧ではID順に上から表示されます。',
        params: DEFAULT_STEP_PARAMS
      })
      steps = steps.concat([
        {
          target: '#userName',
          content: 'メンバーの氏名を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#userTags',
          content: 'メンバーに付与するタグを選択してください。<br>メール送信時にタグによる対象者選択が行えます。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#mailAddr',
          content: (this.useMobile ? '' : 'メールアドレスもしくはSMSの指定が必須です。<br>') + 'メールアドレスは３つまで登録可能です。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#phoneNo',
          content: '必要に応じてショートメールの送信先（電話番号）<br>を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#notify',
          content: '入力した送信先に設定完了メールを配信する場合、<br>選択してください。',
          params: DEFAULT_STEP_PARAMS
        }
      ])
      if (this.isNew) {
        steps.push({
          target: '#insertButton',
          content: '最後に登録ボタンをクリックして登録完了です。<br>エラーがある場合、項目下に赤字で表示されます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
      } else {
        steps.push({
          target: '#updateButton',
          content: '更新ボタンをクリックして編集完了です。<br>エラーがある場合、項目下に赤字で表示されます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
        steps.push({
          target: '#deleteButton',
          content: '登録済のメンバーを削除できます。<br>削除は元に戻せませんので注意してください。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
      }
      return steps
    },

    fetchData () {
      this.loading = true
      this.error = null
      
      const params = {
        'corporation_id': this.memberCorporationId, 
        'user_id': this.p_user_id
      }
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.user = res.data[0]
            if (!this.user.tags)
              this.user.tags = []

            let meta = this.user.meta
            delete this.user.meta
            if (meta.bounce.length) {
              this.bounceWarning = '配信に失敗している送信先があります。（' + meta.bounce.join(', ') + '）'
            }

            this.$v.$touch()
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.user = {}
          this.error = res.response
        })
    },

    fetchTempData () {
      this.loading = true
      
      const params = {
        'corporation_id': this.memberCorporationId, 
        'temp_user_id': this.p_temp_user_id
      }
      this.$store.dispatch('http/get', { apiName: 'canaryDynamo', path: '/temporary_employee', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.user = res.data[0]
            this.user.notify = true
            this.$v.$touch()
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.user = {}
          this.error = res.response
        })
    },

    backToList () {
      this.$router.push({ name: 'Users' })
    },

    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const params = this.user
      params['corporation_id'] = this.memberCorporationId
      params['tags'] = this.sortedUserTagIds(this.user.tags) // 有効なタグのみ残すため

      this.sending.create = true
      this.$store.dispatch('http/post', { apiName: 'canaryEmployee', data: params }, { root: true })
        .then(() => {
          if (this.self) {
            const params = {
              'corporation_id': this.memberCorporationId,
              'temp_user_id': this.p_temp_user_id
            }
            this.$store.dispatch('http/delete', { apiName: 'canaryDynamo', path: '/temporary_employee', data: params }, { root: true })
          }
          this.msgBoxOk('登録しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    },
    
    updateData () {
      // 呼び出し時にtouch済
      if (this.$v.$invalid) {
        return
      }

      const params = this.user
      params['corporation_id'] = this.memberCorporationId
      params['tags'] = this.sortedUserTagIds(this.user.tags) // 有効なタグのみ残すため
      
      this.sending.update = true
      this.$store.dispatch('http/put', { apiName: 'canaryEmployee', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('更新しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    },

    deleteDataWithConfirm () {
      this.msgBoxConfirm('本当に削除してよろしいですか？', {okTitle: '削除する'})
        .then(val => {
          if (val) this.deleteData()
        })
    },

    deleteData () {
      const params = {
        'corporation_id': this.memberCorporationId,
        'user_id': this.p_user_id
      }

      this.sending.delete = true
      this.$store.dispatch('http/delete', { apiName: 'canaryEmployee', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('削除しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    }
  }

}
</script>