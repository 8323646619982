<template>
  <b-container>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <b-row align-h="center">
      <b-table :busy.sync="loading" thead-class="c-theme" class="text-center"
          :items="templates" :fields="fields"
          striped hover>
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:cell(edit)="{ item }">
          <router-link :to="{ name: 'TemplateUpdate', params: { id: item.caption }}">
            <b-button class="mx-1" variant="outline-secondary">編集<span class="d-none d-sm-inline">する</span></b-button>
          </router-link>
        </template>
      </b-table>    
      
      <div class="buttonGroup">
        <b-button variant="secondary" to="templates/new">追加する</b-button>
      </div>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Templates',
  data () {
    return {
      loading: false,
      fields: [
        { key: 'caption', label: '見出し' },
        { key: 'subject', label: '件名' },
        { key: 'edit', label: '' }
      ],
      templates () {
        return []
      },
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.loading = true
      this.error = null

      let params = {
        'corporation_id': this.corporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryQTemplate', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.templates = res.data
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    }
  }
}
</script>
