<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <div v-if="!loading" class="inputForm">
      <b-row class="mt-4 text-center">
        <b-col>未回答者にメールを再送信します。</b-col>
      </b-row>

      <div v-show="!isSelectUser">
        <div>
          <b-row class="mt-1 text-center">
            <b-col>件名と本文のみ変更できます。</b-col>
          </b-row>
        </div>
        
        <div>
          <b-row class="mt-4 mb-3 text-center">
            <b-col>＜配信内容＞</b-col>
          </b-row>

          <b-form-group class="my-2" label-cols-sm="2" label="件名" label-for="subject">
            <b-input id="subject" v-model="summary.subject" autocomplete="off" :required="true"/>
          </b-form-group>
          <b-row v-if="$v.summary.subject.$error">
            <b-col offset-sm="2">
              <div class="error" v-if="!$v.summary.subject.required">件名を入力してください</div>
            </b-col>
          </b-row>

          <b-form-group class="my-2" label-cols-sm="2" label="本文" label-for="body">
            <b-form-textarea id="body" v-model="summary.body" rows="4" max-rows="8"></b-form-textarea>
            <span class="smaller text-info font-italic">※本文以下に回答のためのリンクが挿入されます</span>
          </b-form-group>
          <b-row v-if="$v.summary.body.$error">
            <b-col offset-sm="2">
              <div class="error" v-if="!$v.summary.body.required">本文を入力してください</div>
            </b-col>
          </b-row>
        </div>

        <b-row class="buttonGroup text-center">
          <b-col>
            <b-button id="backButton" variant="secondary" @click="backToSummary">集計結果に戻る</b-button>
            <span class="mx-1 mx-sm-2"/>
            <b-button id="sendButton" variant="primary" :disabled="!selectedUserId.length" @click="createDataWithConfirm">
              <b-spinner small v-if="sending"></b-spinner><b-icon-envelope v-if="!sending" class="mr-2"/>送信する（{{ selectedUserId.length }}名）
            </b-button>
            <span class="mx-1 mx-sm-2"/>
            <b-button id="selectButton" variant="outline-primary" v-show="!isSelectUser" @click="viewSelectUser">
              <b-icon-people class="mr-2"/>送信先を選択する
            </b-button>
          </b-col>
        </b-row>
      </div>

      <div v-show="isSelectUser">
        <b-row class="mt-4 mb-3 text-center">
          <b-col>＜送信先選択＞</b-col>
        </b-row>

        <SelectUsers ref="selectUsers" :targetUserId="targetUserId" @selected="onSelectUsers"></SelectUsers>

        <b-row class="buttonGroup text-center">
          <b-col>
            <b-button id="backButton" variant="secondary" @click="cancelSelectUser">戻る</b-button>
            <span class="mx-1 mx-sm-2"/>
            <b-button id="sendButton" variant="primary" :disabled="!selectedUserId.length" @click="createDataWithConfirm">
              <b-spinner small v-if="sending"></b-spinner><b-icon-envelope v-if="!sending" class="mr-2"/>送信する（{{ selectedUserId.length }}名）
            </b-button>
          </b-col>
        </b-row>
      </div>

      <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
      <b-button variant="warning" class="help" @click="$tours['helpTour'].start()">
        <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
      </b-button>

    </div>
  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import SelectUsers from '@/components/SelectUsers.vue'

export default {
  name: 'ResendMail',
  components: {
    SelectUsers
  },
  props: ['questionnaireId'],
  data () {
    return {
      loading: false,
      sending: false,
      isSelectUser: false,
      selectedUserId: [],
      targetUserId: [],
      summary: {},
      error: null,
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      },
      steps: []
    }
  },
  validations: {
    summary: {
      subject: {
        required
      },
      body: {
        required
      }
    }
  },
  created () {
    this.fetchData()
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  methods: {
    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps
      steps = [
        {
          target: '#subject',
          content: '送信するメールの件名を設定します。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#body',
          content: '送信するメールの本文を設定します。<br>実際には本文以下に<b>回答のためのリンク</b>が<br>挿入されます。',
          params: DEFAULT_STEP_PARAMS
        }]
      steps = steps.concat([
        {
          target: '#sendButton',
          content: '最後に送信ボタンをクリックして完了です。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        }
      ])
      return steps
    },

    fetchData () {
      this.loading = true

      const params = {
        'corporation_id': this.corporationId,
        'questionnaire_id': this.questionnaireId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryQResult', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.summary = res.data[0]
            this.targetUserId = this.summary.summary_user.not_responsed.map(v => v.user_id)
            this.selectedUserId = this.targetUserId
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    },

    backToSummary () {
      // タブ選択等を復元させるため履歴で戻る
      //this.$router.push({ name: 'ResponseSummary', params: {'id': this.questionnaireId} })
      this.$router.go(-1)
    },

    createDataWithConfirm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const h = this.$createElement
      const userSize = this.selectedUserId.length      
      const messageVNode = h('div', { class: ['text-left'] }, [
        h('p', 'この内容でメールを送信してよろしいですか？'),
        h('span', { class: ['ml-3'] }, ['送信先　： ', h('span', { class: ['text-primary'] }, userSize + '名')]), h('br')
      ])          

      this.msgBoxConfirm(messageVNode, {okVariant: 'primary', okTitle: '送信する', autoFocusButton: 'ok'})
        .then(val => {
          if (val) this.createData()
        })
    },

    createData () {
      const params = {}
      params['corporation_id'] = this.corporationId
      params['resend_id'] = this.$store.getters['app/generateUuid']()
      params['questionnaire_id'] = this.questionnaireId
      params['user_id'] = this.selectedUserId
      params['disclosure'] = this.summary.disclosure
      params['subject'] = this.summary.subject
      params['body'] = this.summary.body
      params['confirm_item'] = this.summary.confirm_item

      this.sending = true
      this.$store.dispatch('http/post', { apiName: 'canaryDynamo', path: '/questionnaire_resend', data: params }, { root: true })
        .then(() => {
          const h = this.$createElement
          const messageVNode = h('div', { class: ['text-center'] }, [
            h('b-icon', { props: { icon: 'envelope', fontScale: 2.6 }, 
              style: { color: 'mediumseagreen', margin: '0 40px 5px 30px', animation: 'sending 1.5s ease-in-out infinite' } }),
            h('b-icon', { props: { icon: 'mailbox2', fontScale: 4.2 }, style: { color: 'mediumaquamarine' } }),
            h('p', { class: ['text-center'], style: { fontSize: '1.2rem', marginBottom: '0' }}, 'メールを送信しました')
          ])          
          this.msgBoxOk(messageVNode)
            .then(this.backToSummary)
        })
        .catch(res => {
          this.error = res.response
        })
    },
    
    viewSelectUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.$refs.selectUsers.selectAllRows()
      this.isSelectUser = true
    },

    cancelSelectUser () {
      this.isSelectUser = false
      this.selectedUserId = this.targetUserId
    },
    
    onSelectUsers (users) {
      this.selectedUserId = users
    }    
  }
}
</script>
