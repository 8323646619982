<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <b-form v-if="!loading" class="inputForm" @submit.stop.prevent>
      <b-form-group class="my-2" label-cols-sm="2" label="見出し" label-for="caption"
          :description="isNew ? '定型文につける名前として使用します' : ''">
        <b-input id="caption" autocomplete="off" v-model="template.caption" :required="true" :disabled="!isNew" :autofocus="isNew"
          @blur="$v.template.caption.$touch()"/>
      </b-form-group>
      <b-row v-if="$v.template.caption.$error">
        <b-col offset-sm="2">
          <div class="error" v-if="!$v.template.caption.required">見出しを入力してください</div>
          <div class="error" v-if="!$v.template.caption.isUnique">同じ見出しが既に使用されています</div>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-3 text-center">
        <b-col>＜配信内容＞</b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="2" label="件名" label-for="subject">
        <b-input id="subject" v-model="template.subject" autocomplete="off" :required="true" :autofocus="!isNew"/>
      </b-form-group>
      <b-row v-if="$v.template.subject.$error">
        <b-col offset-sm="2">
          <div class="error" v-if="!$v.template.subject.required">件名を入力してください</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="2" label="本文" label-for="body">
        <b-textarea id="body" v-model="template.body" rows="4" max-rows="8"></b-textarea>
        <span class="smaller text-info font-italic">※本文以下に回答のためのリンクが挿入されます</span>
      </b-form-group>
      <b-row v-if="$v.template.body.$error">
        <b-col offset-sm="2">
          <div class="error" v-if="!$v.template.body.required">本文を入力してください</div>
        </b-col>
      </b-row>

      <QuestionnaireItems :template="template" :validator="$v.template"/>

      <b-row class="buttonGroup text-center" :show="loading && !error">
        <b-col>
          <div v-if="isNew">
            <b-button id="insertButton" variant="primary" @click="createData">
              <b-spinner small v-if="sending.create"></b-spinner>登録する
            </b-button>
          </div>
          <div v-if="!isNew">
            <b-button id="updateButton" variant="primary" @click="updateData">
              <b-spinner small v-if="sending.update"></b-spinner>更新する
            </b-button>
            <span class="mx-3"/>
            <b-button id="deleteButton" variant="outline-danger" @click="deleteDataWithConfirm">
              <b-spinner small v-if="sending.delete"></b-spinner>削除する
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
    <b-button variant="warning" class="help" @click="$tours['helpTour'].start()">
      <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
    </b-button>

  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { default as qHandler } from '@/const/questionnaireHandler'
import QuestionnaireItems from '@/components/QuestionnaireItems.vue'

export default {
  name: 'TemplateDetail',
  props: ['isNew', 'p_caption'],
  components: {
    QuestionnaireItems
  },
  data () {
    return {
      loading: false,
      sending: {},
      template: this.emptyData(),
      error: null,
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      }
    }
  },
  validations: {
    template: {
      caption: {
        required,
        async isUnique(value) {
          if (!value || !this.isNew) return true
          const params = {
            'corporation_id': this.corporationId, 
            'caption': value
          }
          return await this.$store.dispatch('http/get', { apiName: 'canaryDynamo', path: '/questionnaire_template', params: params }, { root: true })
            .then(res => {
              if (res.data.length == 0) {
                return true
              }
              return false
            })
            .catch(() => {
              return false
            })
        }
      },
      subject: {
        required
      },
      body: {
        required
      },
      confirm_item: {
        $each: {
          question: {
            required,
          },
          choice: {
            $each: {
              required
            }
          },
          choiceMinLength: qHandler.choiceMinLength
        }
      }
    }
  },
  created () {
    this.init()
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  watch: {
    '$route': 'init'
  },
  methods: {
    init () {
      if (this.isNew) {
        this.template = this.emptyData()
      } else {
        this.fetchData()
      }
    },

    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps = []
      if (this.isNew) steps.push({
        target: '#caption',
        content: '定型文につける名前を設定してください。',
        params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
      })
      steps = steps.concat([
        {
          target: '#subject',
          content: '送信するメールの件名を設定します。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        },
        {
          target: '#body',
          content: '送信するメールの本文を設定します。<br>実際には本文以下に<b>回答のためのリンク</b>が<br>挿入されます。',
          params: DEFAULT_STEP_PARAMS
        }
      ])
      if (this.template.confirm_item.length) steps = steps.concat([
        {
          target: '.question',
          content: '質問を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.questionType',
          content: '必要に応じて質問形式を選択してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.choice',
          content: '回答の選択肢を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.addChoice',
          content: '必要に応じて回答の選択肢を追加できます。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.delChoice',
          content: '不要な選択肢を削除できます。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.choiceRequired',
          content: '質問に対する回答が必須かどうか設定できます。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '.delItem',
          content: '不要な質問を削除できます。',
          params: DEFAULT_STEP_PARAMS
        },
      ])
      steps.push({
        target: '.addItem',
        content: '必要に応じて質問を追加できます。',
        params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
      })
      if (this.isNew) {
        steps.push({
          target: '#insertButton',
          content: '最後に登録ボタンをクリックして登録完了です。<br>エラーがある場合、項目下に赤字で表示されます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
      } else {
        steps.push({
          target: '#updateButton',
          content: '更新ボタンをクリックして編集完了です。<br>エラーがある場合、項目下に赤字で表示されます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
        steps.push({
          target: '#deleteButton',
          content: '登録済の定型文を削除できます。<br>削除は元に戻せませんので注意してください。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        })
      }
      return steps
    },

    addItem () {
      this.template.confirm_item.push(this.emptyDataItem())
    },

    delItem(item_index) {
      this.template.confirm_item.splice(item_index, 1)
      this.$tours['helpTour'].stop()
    },

    emptyData () {
      return {
        disclosure: 0,
        confirm_item: [this.emptyDataItem()]
      }
    },
    
    emptyDataItem () {
      return {
        choice: [''],
        required: true
      }
    },

    fetchData () {
      this.loading = true
      this.error = null
      
      let params = {
        'corporation_id': this.corporationId, 
        'caption': this.p_caption
      }
      this.$store.dispatch('http/get', { apiName: 'canaryQTemplate', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.template = res.data[0]
            this.template.disclosure |= 0
            this.$v.$touch()
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.template = {}
          this.error = res.response
        })
    },

    backToList () {
      this.$router.push({ name: 'Templates' })
    },

    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      const params = this.template
      params['corporation_id'] = this.corporationId

      this.sending.create = true
      this.$store.dispatch('http/post', { apiName: 'canaryQTemplate', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('登録しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    },
    
    updateData () {
      // 呼び出し時にtouch済だが、質問追加にも対応するため再度touchする
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      let params = {
        'corporation_id': this.corporationId,
        'caption': this.p_caption,
        'template': this.template
      }
      
      this.sending.update = true
      this.$store.dispatch('http/put', { apiName: 'canaryQTemplate', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('更新しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    },

    deleteDataWithConfirm () {
      this.msgBoxConfirm('本当に削除してよろしいですか？', {okTitle: '削除する'})
        .then(val => {
          if (val) this.deleteData()
        })
    },
    
    deleteData () {
      const params = {
        'corporation_id': this.corporationId,
        'caption': this.p_caption
      }

      this.sending.delete = true
      this.$store.dispatch('http/delete', { apiName: 'canaryQTemplate', data: params }, { root: true })
        .then(() => {
          this.msgBoxOk('削除しました')
            .then(this.backToList)
        })
        .catch(res => {
          this.error = res.response
        })
    }
  }

}
</script>

<style scoped>
.choice { border-radius: 0.1rem !important; }
.delChoice { border: 0 !important; }
</style>