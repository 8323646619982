<template>
  <b-container>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>
    
    <b-alert :show="!!warning" variant="warning" class="mb-4" dismissible>
      <b-icon-exclamation-triangle class="lead mr-2"/>{{ warning }}（<a href="#" @click="fetchData">全件取得</a>）
    </b-alert>

    <b-row align-h="center">
      <b-table :busy.sync="loading" thead-class="c-theme" class="text-center"
          :items="responses" :fields="fields" 
          :filter="filter" :filterIncludedFields="filterOn"
          :current-page="currentPage"
          :per-page="perPage"
          striped hover>
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:cell(summary)="{ item }">
          <span :class="(item.summary_count.responsed == item.summary_count.total && item.summary_count.total != 0 ? 'text-success' : 'text-danger')">
          {{ item.summary_count.responsed }} / {{ item.summary_count.total }}<br>
          ({{ toParcentage(item.summary_count.responsed, item.summary_count.total) }})
          </span>
        </template>
        <template v-slot:cell(create_datetime)="{ item }">
          <span class="text-nowrap mr-md-1">{{ item.create_date }}<br class="d-inline d-md-none"></span>
          {{ item.create_time.substring(0, 5) }}
        </template>
        <template v-slot:cell(detail)="{ item }">
          <router-link :to="{ name: 'ResponseSummary', params: { id: item.questionnaire_id }}">
            <b-button class="mx-1" variant="outline-secondary">詳細</b-button>
          </router-link>
        </template>
      </b-table>    
    </b-row>

    <b-row v-show="responses && (responses.length > perPage)">
      <b-col class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="responses.length"
          :per-page="perPage"
          align="right"
          class="my-0"></b-pagination>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
const MAX_ROW_COUNT = 30

export default {
  name: 'Responses',
  data () {
    return {
      loading: false,
      fields: [
        { key: 'summary', label: '回答数（率）' },
        { key: 'subject', label: '件名' },
        { key: 'create_datetime', label: '送信日時' },
        { key: 'detail', label: '' }
      ],
      filter: null,
      filterOn: ['create_date'],
      currentPage: 1,
      perPage: 10,
      responses () {
        return []
      },
      error: null,
      warning: null
    }
  },
  computed: {
    today: function () {
      return this.$store.getters['app/today']()
    }
  },
  created () {
    //this.filter = this.today
    this.fetchData({maxRowCount: MAX_ROW_COUNT})
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    toParcentage (count, total) {
      if (total == 0) {
        return '--.-%'
      }
      return (parseFloat(count / total) * 100).toFixed(1) + '%' 
    },

    fetchData ({maxRowCount = 0}) {
      this.loading = true
      this.error = null
      this.warning = null
      this.currentPage = 1

      const params = {
        'corporation_id': this.corporationId,
        'limit': maxRowCount ? maxRowCount + 1 : 0
      }
      this.$store.dispatch('http/get', { apiName: 'canaryQResult', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            if (maxRowCount && res.data.length > maxRowCount) {
              res.data = res.data.slice(0, maxRowCount)
              this.warning = `最新の${maxRowCount}件までを表示しています。`
            }
            this.responses = res.data
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    }
  }
}
</script>
