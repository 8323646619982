<template>
  <div>
    <caption>
      メンバーに付与するタグを編集できます
    </caption>
    
    <b-tags v-model="sortedViewNames" size="lg" :autofocus="true" no-outer-focus class="mb-2">
      <template v-slot="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
        
        <b-input-group class="mb-2">
          <b-form-input
            v-bind="inputAttrs"
            v-on="inputHandlers"
            placeholder="新しいタグを入力します"
            class="form-control"></b-form-input>
          <b-input-group-append>
            <b-button @click="addTag()" variant="secondary">追加</b-button>
          </b-input-group-append>
        </b-input-group>
        
        <div class="d-inline-block mt-2">
          <draggable tag="div" :list="sortedViewNames" class="list-inline d-inline-block mb-2"
              v-bind="dragOptions" @start="drag = true" @end="drag = false">
            <b-tag v-for="(tag, index) in tags" :key="tag"
                @remove="removeTagWithConfirm(removeTag, tag)" class="userTag mr-2">
              <b-icon-tag class="mr-1" :style="{cursor: drag ? 'grabbing' : 'grab'}"/>
              <span class="tagContent px-1 mx-1" contenteditable="true" 
                  @focus.self="escapeContent(index)" 
                  @blur.self="applyContent(index, $event.target)"
                  @keydown.enter="if ($event.keyCode == 13) $event.target.blur()">{{ tag }}</span>
            </b-tag>
          </draggable>
        </div>
        
      </template>
    </b-tags>

    <caption class="small pb-2">
      ・追加済みのタグの名称を変更するには、名称をクリックしてください<br>
      ・表示順を入れ替えるには、タグ左側アイコンのドラッグで移動させてください
    </caption>

    <b-row class="mt-2 text-right">
      <b-col>
        <b-button id="cancelButton" variant="outline-secondary" @click="close">
          更新せずに閉じる
        </b-button>
        <span class="mx-1"/>
        <b-button id="updateButton" variant="primary" @click="updateUserTags">
          更新する
        </b-button>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'UserTags',
  props: ['close'],
  components: {
    'draggable': draggable,
  },
  data() {
    return {
      drag: false,
      editTags: [],
      sortedViewNames: [],
      editingTag: {}
    }
  },
  computed: {
    ...mapGetters({
      generateShortId: 'app/generateShortId',
      userTags: 'auth/userTags'
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  created () {
    this.editTags = this.userTags.map(tag => ({...tag})) //copy
    this.sortedViewNames = this.userTags.map(tag => tag.name)
  },
  methods: {
    escapeContent (index) {
      this.editingTag = this.editTags.find(el => el.name == this.sortedViewNames[index])
    },
    applyContent (index, target) {
      target.innerText = target.innerText.replace(/[\r\n]/g, '').trim()
      this.sortedViewNames.splice(index, 1, target.innerText)
      if (this.editingTag) {
        this.editingTag.name = target.innerText
      }
    },
    updateUserTags () {
      const newTags = this.sortedViewNames.map(val => {
        return this.editTags.find(el => el.name == val) || {id: this.generateShortId(), name: val}
      })
      const params = {
        'corporation_id': this.corporationId,
        'member_tags': newTags
      }
      this.$store.dispatch('http/put', { apiName: 'canaryCorpTags', data: params }, { root: true })
        .then(res => {
          if (res.data) {
            this.$store.dispatch('auth/saveUserTags', newTags)
            this.msgBoxOk('タグを更新しました')
              .then(this.close)
          }
        })
        .catch(res => {
          this.error = res.response
        })      
    },
    removeTagWithConfirm (removeTag, tag) {
      if (this.userTags.find(el => el.name == tag)) {
        this.msgBoxConfirm('このタグが使用されていた場合、その指定は無効となります。本当に削除してよろしいですか？', {okTitle: '削除する'})
          .then(val => {
            if (val) removeTag(tag)
          })
      } else {
        removeTag(tag)
      }
    }
  }
}
</script>

<style>
caption {
  display: inline-block;
  padding-top: 0;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: skyblue;
}
.tagContent:focus {
  background-color: whitesmoke;
  color: black;
}
</style>