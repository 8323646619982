<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <b-form v-if="!loading" class="inputForm" @submit.stop.prevent>
    
      <h3 class="mt-3 mb-4"><b-icon-gear class="mr-2"/>設定</h3>

      <b-form-group class="my-4" label-cols-sm="3" label="メールアドレス" label-for="mailAddress"
          description="送信元メールアドレスです。変更希望は別途お問い合わせください。">
        <span id="mailAddress">{{ config.mail_address }}</span>
      </b-form-group>

      <b-form-group class="my-4" label-cols-sm="3" label="メール表示名" label-for="mailFromName"
          description="送信元メールアドレスの表示名として使用されます。">
        <b-input id="mailFromName" type="text" v-model.trim="config.mail_from_name" :autofocus="true"/>
      </b-form-group>
      <b-row v-if="$v.config.mail_from_name.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.config.mail_from_name.required">メール表示名を入力してください</div>
        </b-col>
      </b-row>

      <b-form-group class="my-4" label-cols-sm="3" label="署名" label-for="signature"
          description="送信するメール本文の末尾に自動的に追加されます。">
        <b-form-textarea id="signature" v-model="config.signature" rows="5" max-rows="8"></b-form-textarea>
      </b-form-group>

      <b-row class="buttonGroup text-center" :show="loading && !error">
        <b-col>
          <b-button id="backButton" variant="secondary" @click="historyBack()">戻る</b-button>
          <span class="mx-3"/>
          <b-button id="updateButton" variant="primary" @click="updateData">
            <b-spinner small v-if="sending"></b-spinner>保存する
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-container>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'Config',
  data () {
    return {
      loading: false,
      sending: false,
      config: {},
      error: null
    }
  },
  validations: {
    config: {
      mail_from_name: {
        required
      }
    }
  },
  created () {
    this.init()
  },
  watch: {
    '$route': 'init'
  },
  methods: {
    init () {
      this.fetchData()
    },

    fetchData () {
      this.loading = true

      const params = {
        'corporation_id': this.corporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryConfig', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.config = res.data
            this.$v.$touch()
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.config = {}
          this.error = res.response
        })
    },
    
    updateData () {
      // 呼び出し時にtouch済
      if (this.$v.$invalid) {
        return
      }

      const params = this.config
      params['corporation_id'] = this.corporationId
      
      this.sending = true
      this.$store.dispatch('http/put', { apiName: 'canaryConfig', data: params }, { root: true })
        .then(() => {
          this.sending = false
          this.msgBoxOk('設定を保存しました')
        })
        .catch(res => {
          this.error = res.response
        })
    },

    historyBack() {
      this.$router.go(-1)
    }
  }

}
</script>
