<template>
  <b-container>
    <b-alert :show="!!error" variant="danger">
      {{ error ? (error.response.data || error.response.statusText || error.response.status) : '' }}
    </b-alert>

    <b-row align-h="center" class="m-4">
      <p class="text-info">
        メンバー情報は別のアカウントにより管理されています。<br>
        追加・編集を行いたい場合は、管理者にお問い合わせください。
      </p>
    </b-row>

    <b-row align-h="center" class="m-5" v-if="isEmpty">
      <p class="font-weight-bold text-dark" style="font-size: larger;">まだメンバーの登録がありません。</p>
    </b-row>

    <b-row align-h="center" v-if="users.length">
      <b-table :busy.sync="loading" thead-class="c-theme" class="text-center"
          :items="users" :fields="fields" 
          striped hover>
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
          <col v-for="field in scope.fields"
            :key="field.key"
            :style="field.style || {}">
        </template>

        <template v-slot:head(user_id)="data">
          <div class="d-none d-sm-inline">{{ data.label }}</div>
        </template>
        <template v-slot:head(stats)="data">
          <div class="d-none d-sm-inline">{{ data.label }}</div>
        </template>
        
        <template v-slot:cell(tag_names)="{ item }">
          <b-tag v-for="tagName in item.tag_names" class="userTag" :key="tagName" :no-remove="true">{{ tagName }}</b-tag>
        </template>

        <template v-slot:cell(stats)="{ item }">
          <template v-if="item.meta.registed">
            <div v-if="item.last_message_id">
              <span class="text-nowrap" v-if="item.meta.delivery_count" v-b-popover.hover.top="'配信成功'">
                <b-icon-envelope-open scale="1.2" variant="success"/>
                <code class="mailCount ml-1" v-if="item.meta.delivery_count > 1">{{ item.meta.delivery_count || '-' }}</code>
              </span>
              <span class="text-nowrap" v-if="item.meta.bounce_count" v-b-popover.hover.top="'配信失敗'" :class="{'ml-sm-2': item.meta.delivery_count}">
                <b-icon-exclamation-triangle-fill scale="1.2" variant="warning"/>
                <code class="mailCount ml-1" v-if="item.meta.bounce_count > 1">{{ item.meta.bounce_count || '-' }}</code>
              </span>
              <span v-if="!item.meta.delivery_count && !item.meta.bounce_count" v-b-popover.hover.top="'配信中'">
                <b-icon-envelope scale="1.2" variant="secondary"/>
              </span>
            </div>
            <div v-else>
              <b-icon-dash scale="1.2" v-b-popover.hover.top="'未配信'"/>
            </div>
          </template>
          <span class="text-nowrap" v-else v-b-popover.hover.top="'配信先なし'">
            <b-icon-exclamation-triangle scale="1.2" variant="danger"/>
            <span class="smaller text-secondary">配信先なし</span>
          </span>
        </template>
      </b-table>    
    </b-row>

  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Users',
  data () {
    return {
      loading: false,
      fields: [
        { key: 'user_id', label: 'メンバーID' },
        { key: 'user_name', label: '氏名', tdClass: 'text-nowrap' },
        { key: 'tag_names', label: '所属／分類' },
        { key: 'stats', label: '直近の配信', style: { width: '110px' } }
      ],
      users () {
        return []
      },
      hoverItem: null,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    ...mapGetters({
      sortedUserTagNames: 'auth/sortedUserTagNames'
    }),
    isEmpty() {
      return !this.loading && this.users.length == 0
    }
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.loading = true
      this.error = null

      this.fetchUserData()
    },

    fetchUserData () {
      const params = {
        'corporation_id': this.memberCorporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
        .then(res => {
          if (res.data) {
            this.users = res.data
            this.users.forEach(user => {
              user.tag_names = this.sortedUserTagNames(user.tags)
            })
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
        .finally(() =>
          this.loading = false
        )
    }
  }

}
</script>

<style scoped>
.popover {
  border-color: gray;
}
.mailCount {
  color: #343a40 !important;
  vertical-align: bottom;
}
</style>