<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <div v-if="!loading">
      <b-row class="headerAttr my-2">
        <b-col sm="2" offset-sm="3">
          <span>氏名：</span>
        </b-col>
        <b-col sm="4">
          <span class="ml-3">{{ userName }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col sm="2" offset-sm="3">
          <span>回答日時：</span>
        </b-col>
        <b-col sm="4">
          <span class="ml-3">{{ questionnaire_result.response_date }} {{ questionnaire_result.response_time }}</span>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-3 text-center">
        <b-col>＜確認項目＞</b-col>
      </b-row>

      <b-row align-h="center" class="text-center">
        <b-col md="9">
          <b-card align="center" class="m-4" v-if="!questionnaire_result.responsed || !questionnaire_result.response_item.length">
              <b-container>{{ questionnaire_result.responsed ? "質問なし" : "回答なし" }}</b-container>
          </b-card>
        </b-col>
      </b-row>
            
      <b-row align-h="center" v-for="item in questionnaire_result.response_item" :key="item.question">
        <b-col md="9">
          <b-card align="left" class="questionCard my-2" header-tag="header">
            <template v-slot:header>
              <span class="mr-2">質問：</span>
              {{ item.question }}
            </template>
            <div>
              <span class="mr-2">回答：</span>
              <template v-if="qHandler.isTypeCheck(item)">
                <div v-for="(selected, index) in qHandler.sortedSelectedList(item)" :key="index" class="pl-3">
                  {{ selected || item.otherText }}
                </div>
              </template>
              <template v-else>
                {{ qHandler.toDispChoiceText(item) }}
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="buttonGroup text-center">
        <b-col>
          <div>
            <b-button id="backButton" variant="secondary" @click="historyBack()">集計結果に戻る</b-button>
            <b-button id="responseButton" variant="outline-primary" v-if="!questionnaire_result.responsed" class="ml-3" 
             target="_blank" :to="{ name: 'ViewForm', params: { corpId: $store.state.auth.corporationId, ruid: questionnaire_result.ruid }}">
              代理回答する<b-icon-box-arrow-up-right class="ml-2" scale="0.8"/>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { default as qHandler } from '@/const/questionnaireHandler'

export default {
  name: 'ResponseDetail',
  props: ['questionnaireId', 'ruid'],
  data () {
    return {
      loading: false,
      userName: '',
      questionnaire_result: {},
      error: null,
      qHandler
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.loading = true
      this.error = null

      const params = {
        'corporation_id': this.corporationId, 
        'ruid': this.ruid
      }
      this.$store.dispatch('http/get', { apiName: 'canaryDynamo', path: '/questionnaire_result', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.questionnaire_result = res.data[0]
            this.fetchUserName(res.data[0].user_id)
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.questionnaire_result = {}
          this.error = res.response
        })
    },

    fetchUserName (userId) {
      const params = {
        'corporation_id': this.memberCorporationId, 
        'user_id': userId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
        .then(res => {
          this.userName = res.data[0].user_name
        })
        .catch(() => {
          this.userName = userId 
        })
    },

    historyBack() {
      this.$router.go(-1)
    }
  }
}
</script>