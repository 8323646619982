<template>
  <b-container class="selectUsers">

    <b-row class="my-3" v-if="userTags.length > 0">
      <b-form-group id="tagSelectArea" class="w-100 pt-2 px-2 border border-secondary rounded" label-cols-sm="3" label="所属／分類で選択">
        <ul class="list-inline d-inline-block mb-2">
          <li v-for="tag in userTags" :key="tag.id" class="list-inline-item">
            <b-button size="sm" :pressed.sync="tagSelect[tag.id]" variant="outline-success" class="my-1" @click="selectByTag(tag.id)">
              <b-icon-check2 v-if="tagSelect[tag.id]" class="mr-1"/>{{ tag.name }}
            </b-button>
          </li>
        </ul>
      </b-form-group>
    </b-row>

    <b-row class="mb-2">
      <b-button size="sm" variant="outline-success" @click="selectAllRows"><b-icon-check-all/>全て選択</b-button>
      <span class="mx-2"/>
      <b-button size="sm" variant="outline-secondary" @click="clearSelected">選択解除</b-button>
    </b-row>

    <b-row align-h="center">
      <b-table id="userTable" :busy.sync="loading" thead-class="c-theme" tbody-class="line-higher" class="text-center"
          ref="userTable" selectable select-mode="multi" @row-selected="onRowSelected" 
          :items="users" :fields="fields" striped hover>
          <!-- :filter="tagSelect" :filter-function="tagFilter" -->
        <template v-slot:table-busy>
          <div class="table-loading text-center my-2">
            <strong><b-spinner class="align-middle mr-2"></b-spinner>読み込み中...</strong>
          </div>
        </template>
        <template v-slot:table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key === 'selected' ? '70px' : '' }">
        </template>
        <template v-slot:head(user_id)="data">
          <div class="d-none d-sm-inline">{{ data.label }}</div>
        </template>
        <template v-slot:cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span class="rowCheck" aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>
        <template v-slot:cell(tag_names)="{ item }">
          <b-tag v-for="tagName in item.tag_names" class="userTag" :key="tagName" :no-remove="true">{{ tagName }}</b-tag>
        </template>
      </b-table>    
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SelectUsers',
  props: ['targetUserId'],
  data () {
    return {
      loading: false,
      fields: [
        { key: 'selected', label: '選択', thClass: 'text-nowrap' },
        { key: 'user_id', label: 'メンバーID' },
        { key: 'user_name', label: '氏名', tdClass: 'text-nowrap' },
        { key: 'tag_names', label: '所属／分類' }
      ],
      tagSelect: {},
      users () {
        return []
      },
      error: null
    }
  },
  computed: {
    ...mapGetters({
      userTags: 'auth/userTags',
      sortedUserTagNames: 'auth/sortedUserTagNames'
    })
  },
  created () {
    this.fetchData()
    this.reset()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    reset () {
      this.tagSelect = this.userTags.reduce((acc, v) => {
        acc[v.id] = false
        return acc
      }, {})
    },
    // tagFilter (user, filter) {
    //   return user.tags && user.tags.filter(el => filter[el]).length > 0
    // },
    selectByTag (tag) {
      const selected = this.tagSelect[tag]
      this.users.forEach((user, i) => {
        if (user.tags && user.tags.indexOf(tag) >= 0) {
          if (selected) {
            this.$refs.userTable.selectRow(i)
          } else if (!user.tags.find(el => this.tagSelect[el])) {
            this.$refs.userTable.unselectRow(i)
          }
        }
      })
    },
    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps = [
        {
          target: '#userTable',
          content: '送信対象のメンバーを選択してください。',
          params: DEFAULT_STEP_PARAMS
        }
      ]
      if (this.userTags.length) steps = steps.concat([
        {
          target: '#tagSelectArea',
          content: 'タグによるメンバー選択も可能です。',
          params: DEFAULT_STEP_PARAMS
        }])
      return steps
    },
    fetchData () {
      this.loading = true

      const params = {
        'corporation_id': this.memberCorporationId
      }
      this.$store.dispatch('http/get', { apiName: 'canaryEmployee', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.users = res.data
            this.users.forEach(user => {
              user.tag_names = this.sortedUserTagNames(user.tags)
            })
            if (this.targetUserId) {
              // メンバー削除も考慮して、有効なもののみ設定
              this.users = this.users.filter(user => this.targetUserId.includes(user.user_id))
            }
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    },
    onRowSelected (items) {
      this.selectedUserId = items ? items.map(v => v.user_id) : []
      this.$emit('selected', this.selectedUserId)
    },
    selectAllRows () {
      this.$refs.userTable.selectAllRows()
    },
    clearSelected () {
      this.$refs.userTable.clearSelected()
      this.reset()
    }
  }

}
</script>

<style scoped>
.selectUsers {
  max-width: 800px;
}
.rowCheck {
  color: green;
  font-weight: bold;
}
</style>